import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/SectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";
import { Link } from "gatsby-plugin-react-i18next";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Política de Solicitantes de Empleo")} />
			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Política de Solicitudes de Empleo")} />

			<div className="legalBody section invertColors" id="politicaEmpleo">
				<h2>1. Identificación del Responsable del Tratamiento</h2>
				<p>
					Identidad - Entidad : BEGIN RESTAURANTES, S.L.
					<br />
					CIF/NIF: B40626392
					<br />
					Dirección postal: Av. Cortes Valencianas, 50, CP 46015 Valencia
					(Valencia)
					<br />
					Correo electrónico: rrhh@beginrestaurante.com
					<br />
					Objeto Social: Restauración
					<br />
					Sitio web: https://beginrestaurante.com/
					<br />
					Datos registrales: Inscrita en el Registro Mercantil de Valencia, Tomo
					10754, Libro 8034, Folio 132, Sección 8, Hoja 190498, Inscripción 11.
					<br />
				</p>
				<h2>2. Datos personales tratados</h2>
				<p>
					Los datos personales que trataremos en cualquier solicitud de empleo,
					serán los convencionalmente establecidos para estos casos, como por
					ejemplo, datos identificativos y de contacto, trayectoria académica y
					profesional, cualquier información que el candidato quiera compartir
					voluntariamente con nuestra entidad, así como otros datos que
					recopilemos durante las entrevistas relacionadas con el proceso de
					selección y contratación.
				</p>

				<h2>3. Finalidad del tratamiento de datos personales</h2>
				<p>
					El tratamiento de los datos personales que nos faciliten los
					solicitantes de empleo, será exclusivamente para valorar su
					candidatura, contactarle para posibles entrevistas y sobre el proceso
					de selección, mantenerle informado de las distintas vacantes que se
					produzcan en nuestra organización si nos autoriza a ello y posible
					contratación laboral en caso de producirse. Las comunicaciones se
					realizarán por los medios de contacto que nos facilite en su solicitud
					de empleo (ejem. teléfono o correo electrónico).
					<br />
					Sus datos también se podrán utilizar para pedir referencias sobre su
					trayectoria profesional en las empresas en las que ha nos informe que
					ha trabajado.
					<br />
					En el caso de que proceda o que Usted entienda que deba informarnos
					sobre su grado de minusvalía, intolerancias, alergias o algún otro
					dato sobre su salud y relacionado con el puesto de trabajo solicitado,
					le informamos que estos datos serán tratados para dar cumplimiento al
					artículo 15 de la Ley 31/1995 de
					<br />
					Prevención de Riesgos Laborales en el que debemos conocer dicho dato
					para adecuar el lugar de trabajo a las necesidades del empleado, así
					como con el Real Decreto Legislativo 1/2013, de 29 de noviembre, por
					el que se aprueba el Texto Refundido de la Ley General de derechos de
					las personas con discapacidad y de su inclusión social. Cualquier otro
					dato innecesario para el objeto de esta solicitud, lo destruiremos de
					forma confidencial.
				</p>

				<h2>4. Legitimación</h2>
				<p>
					El tratamiento de sus datos personales por parte de nuestra entidad,
					se realizará con su consentimiento expreso, libre, informado e
					inequívoco, puesto que le informamos de la presente política que tras
					la lectura de esta y de estar conforme, puede voluntariamente
					autorizarnos al tratamiento de sus datos mediante la marcación de las
					casillas dispuestas a tal efecto en nuestros formularios.
					<br />
					A este respecto, le informamos que en cualquier momento puede cambiar
					de opinión y retirar su consentimiento dirigiéndose a nuestra
					dirección postal o correo electrónico.
					<br />
					En el caso de que el usuario sea menor de 16 años, no trataremos sus
					datos personales, pues esta es la edad mínima laboral en España. El
					usuario es el único responsable de la veracidad de los datos que nos
					remite.
				</p>

				<h2>5. Medidas de seguridad</h2>
				<p>
					Garantizamos el buen uso de la información, la confidencialidad de los
					datos personales contenidos en su solicitud de empleo, aplicando todas
					las medidas de seguridad necesarias para velar por su integridad,
					confidencialidad y disponibilidad. En definitiva, dar cumplimiento a
					las obligaciones que nuestra entidad tiene en materia de protección de
					datos personales.
				</p>

				<h2>6. Cesión de datos</h2>
				<p>
					Nuestra entidad le informa que sus datos personales no se cederán a
					terceros, salvo que medie su consentimiento previo, en cuyo caso le
					solicitaremos su consentimiento mediante las casillas dispuestas a tal
					efecto en los formularios de solicitud de empleo y con la debida
					información para que su consentimiento sea informado y libre. <br />
					En ciertas candidaturas, es posible que nuestra entidad cuente con
					empresas de nuestro grupo o proveedores especializados en procesos de
					selección, en cuyo caso serán considerados encargados del tratamiento
					con los que firmaremos el correspondiente contrato de confidencialidad
					y compromiso de adoptar todas las medidas de seguridad necesarias para
					proteger sus datos.
				</p>

				<h2>7. Conservación de sus datos</h2>
				<p>
					Los datos personales proporcionados en calidad de solicitante de
					empleo se conservarán hasta la ocupación de un puesto de trabajo o
					durante un plazo máximo de un (1) año, salvo que nos haya autorizado
					expresamente a incluirle en nuestra bolsa de trabajo en cuyo caso, se
					conservarán durante el tiempo necesario para contemplar su candidatura
					ante posibles vacantes y contactarle para informarle a este respecto.
				</p>

				<h2>8. Derechos de los candidatos</h2>
				<p>
					Mantendremos sus datos como solicitante de empleo según lo expuesto o
					hasta que usted ejerza su derecho de supresión; por tanto, tiene
					derecho a acceder a sus datos personales, rectificar los datos
					inexactos, oponerse o limitar en cualquier momento al tratamiento de
					sus datos personales, incluso solicitar la portabilidad de estos o
					revocar su consentimiento.
					<br />
					Para ejercer sus derechos, deberá hacerlo por escrito a{" "}
					<a href="mailto:contacto@beginrestaurante.com">
						contacto@beginrestaurante.com
					</a>{" "}
					acompañando una copia de su DNI o documento equivalente para para
					facilitarnos su identificación.
				</p>
				<p>
					Si desea trasladar alguna sugerencia o consulta sobre el tratamiento
					de sus datos personales, puede contactar con nuestros consultores de
					protección de datos, indicando el nombre de nuestra empresa o el
					nombre comercial: <br />
					BUSINESS ADAPTER, S.L. <br />
					Ronda Guglielmo Marconi, 11, 26, (Parque Tecnológico) 46980 Paterna
					(Valencia). <br />
					<a href="https://www.businessadapter.es/contacta-con-el-delegado-de-proteccion-de-datos">
						Formulario de atención al interesado
					</a>
				</p>

				<p>
					Le informamos que si usted en tiende que sus derechos han sido
					desatendidos, tiene derecho a realizar una reclamación ante la Agencia
					Española de Protección de Datos en: C/ Jorge Juan, 6, 28001 Madrid o
					en <a href="www.aepd.es">www.aepd.es</a>.
				</p>

				<h2>9. Más información</h2>
				<p>
					Puede saber más sobre como tratamos los datos personales en nuestra{" "}
					<Link to="/legal/pollitica-privacidad">Política de Privacidad</Link>.
				</p>

				<h2>10. Actualización de esta Política</h2>
				<p>
					Nuestra entidad se reserva el derecho a la modificación de esta
					Política sin previo aviso. Es por ello, que recomendamos consultarla
					cada vez que visite nuestra web.
				</p>

				<p style={{ textAlign: "right" }}>
					Actualizado el 6 de octubre de 2023.
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
